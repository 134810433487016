@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.img {
    height: 180px;
    background-size: cover;
}
.calendar {
    width: 100%;
    margin:0 auto;
}
.calendar > div:first-of-type {
    margin-left: 0;
}
.yes, .no {
    width: 7.3333%;
    margin: .5%;
    display: inline-block;
    height: 25px;
    text-align: center;
    color: #fff;
    line-height: 25px;
    text-transform: capitalize;
    border-radius: 2px;

}
.yes {
    background: #f3a500;
}
.no {
    background: #696969;
}

.months {
    width: 50%;
    margin: 0 auto;
}

.menu {
    display: none;
}

.text-yellow {
    color: #f3a500;
}

.logo-website {
    height: 150px;
    margin: 40px auto;
}

.grid-item {
    width: 31%;
    margin: 0 1%;
    display: inline-block;
    margin-bottom: 20px;
}

@media (max-width:991px) {
    .hidden-md-down {
        display:none!important
    }
    .grid-item {
        width: 92%;
        margin: 4%;
    }
}